import Badge from "@/admin/experiences/badges/Badge"
import usePathwayAdminDropdownItems from "@/admin/pathways/hooks/usePathwayAdminDropdownItems"
import AppsSidebarDragDropProvider from "@/apps/sidebar-item/AppsSidebarDragDropProvider"
import AppsSidebarList from "@/apps/sidebar-item/AppsSidebarList"
import { useLabel } from "@/core/context/LabelsContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import generateCoursePaths from "@/organization/common/sidebar/my-experiences-list/util/generateCoursePaths"
import ProductStatusChip from "@/product/common/status-chip/ProductStatusChip"
import LeaveProductButton from "@/product/member/admin/enrollment/LeaveProductButton"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import usePermissions from "@utils/hook/usePermissions"
import { observer } from "mobx-react-lite"
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd"
import { graphql, useFragment } from "react-relay"

type PathwaySidebarItemProps = {
  productKey: any
  testid: string
  dragHandleProps?: DraggableProvidedDragHandleProps
  isDragging: boolean
}

function PathwaySidebarItem({
  productKey,
  testid = "PathwaySidebarItem",
  dragHandleProps,
  isDragging,
}: PathwaySidebarItemProps) {
  const product = useFragment<any>(
    graphql`
      fragment PathwaySidebarItemFragment on Product {
        id
        name
        slug
        status
        type
        badge {
          ...BadgeFragment
        }
        ...usePermissionsFragment
        ...LeaveProductButtonFragment
        viewerMembership {
          id
        }
        ...usePathwayAdminDropdownItemsFragment
      }
    `,
    productKey
  )

  const permissions = usePermissions(product)
  const { viewerMembership } = product
  const COURSE_PATHS = generateCoursePaths(product.slug)
  const isViewingProduct = location.pathname.startsWith(`${COURSE_PATHS.ROOT}/`)
  const isSelected = location.pathname === COURSE_PATHS.DASHBOARD
  const isPathway = product.type === "pathway"
  const pathwayLabel = useLabel("pathway")
  const isPathwaysAdmin = permissions.has("pathways.manage")

  const classes = useStyles()

  const adminDropdownItems = usePathwayAdminDropdownItems({
    pathwayProductKey: product,
    testid,
  })

  const rightContent = getRightContent()

  if (!isPathway) return null

  return (
    <CommunitySidebarItem
      testid={testid}
      name={product.name}
      isDragging={isDragging}
      dragHandleProps={dragHandleProps}
      leftIcon={
        product.badge ? (
          <Badge isSelected={isSelected} badgeKey={product.badge} size={24} />
        ) : (
          <div className={classes.emptyBadgePlaceholder} />
        )
      }
      to={COURSE_PATHS.DASHBOARD}
      selected={isSelected}
      showItemsRoute={COURSE_PATHS.ROOT}
      rightContent={rightContent}
      overflowItems={getOverflowItems()}
      indentNestedItems
    >
      {isViewingProduct && (
        <AppsSidebarDragDropProvider productId={product.id}>
          <AppsSidebarList productId={product.id} testid={testid} />
        </AppsSidebarDragDropProvider>
      )}
    </CommunitySidebarItem>
  )

  function getRightContent() {
    return (
      <>
        {product.status === "draft" && (
          <ProductStatusChip status={product.status} testid={testid} />
        )}
      </>
    )
  }

  function getOverflowItems() {
    if (!viewerMembership && !isViewingProduct && !isPathwaysAdmin) return undefined
    const items = isPathwaysAdmin
      ? adminDropdownItems
      : viewerMembership
      ? [
          <LeaveProductButton
            key={`${testid}.more-actions.${product.slug}.leave`}
            productKey={product}
          >
            {({ onClick }) => (
              <DiscoDropdownItem
                onClick={onClick}
                testid={`${testid}.more-actions.${product.slug}.leave`}
                title={`Leave ${pathwayLabel.singular}`}
                icon={"logout"}
              />
            )}
          </LeaveProductButton>,
        ]
      : []
    if (!items?.length) return undefined
    return <>{items}</>
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyBadgePlaceholder: {
    height: "24px",
    width: "24px",
    borderRadius: theme.measure.borderRadius.default,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default observer(PathwaySidebarItem)
